import React, { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import {
    ColorType,
    createChart,
    IChartApi,
    IPriceLine,
    ISeriesApi,
    LineStyle,
    SeriesMarker,
    Time,
} from 'lightweight-charts';
import dayjs from 'dayjs';
import { includes, sortBy } from 'lodash';
import { debounceTime, Subject } from 'rxjs';
import Typography from '@mui/material/Typography';

// @ts-ignore
import { FiboModel } from '@ystb/backend/dist/fibo/fiboModel';
// @ts-ignore
import { getCandleParts } from '@ystb/backend/dist/utils/candles';
// @ts-ignore
import { TimeFrame, TrendDirection } from '@ystb/backend/dist/common';
// @ts-ignore
import { getPercent } from '@ystb/backend/dist/utils/price';
// @ts-ignore
import { getHumanReadableVolume, getTimeFormat } from '../utils/common';
// @ts-ignore
import { prettyPrintPrice } from '@ystb/backend/dist/utils/common';

export interface IChartCandle {
    open: number;
    close: number;
    low: number;
    high: number;
    time: any;
    volume: number;
    timeFrame: TimeFrame;
}

interface IChartProps {
    data: IChartCandle[];
    timeFrame: TimeFrame;
    fibo: FiboModel | null;
    showEMA12: boolean;
    showEMA26: boolean;
    showEMA200: boolean;
    showSMA9: boolean;
    showSMA21: boolean;
    showSMA200: boolean;
    showPattern: boolean;
    showVolume: boolean;
    showPriceGap: boolean;
    paddingHeight?: number;
    showCurrentCandleDetails?: boolean;
}

interface IDataRef {
    isActivated: boolean;
    sma9: ISeriesApi<any> | null;
    sma21: ISeriesApi<any> | null;
    sma200: ISeriesApi<any> | null;
    ema12: ISeriesApi<any> | null;
    ema26: ISeriesApi<any> | null;
    ema200: ISeriesApi<any> | null;
    candles: ISeriesApi<any> | null;
    volume: ISeriesApi<any> | null;
    fiboLines: IPriceLine[];
    resize$: Subject<{ width: number; height: number }>;
}

interface ITrade {
    isExecuted: boolean;
    buy: number;
    target: number;
    time: Date;
    executedRange?: number;
}

const backgroundColor = 'white';
const lineColor = '#2962FF';
const textColor = 'black';
const lowColor = '#ef5350';
const highColor = '#26a69a';

export const CandlesChart = (props: IChartProps) => {
    const {
        data,
        fibo,
        showSMA9,
        showSMA21,
        showSMA200,
        showPattern,
        showVolume,
        timeFrame,
        showEMA12,
        showEMA26,
        showEMA200,
        showPriceGap,
        paddingHeight = 200,
        showCurrentCandleDetails = false,
    } = props;

    const [selectedCandle, setSelectedCandle] = useState<IChartCandle | null>(null);
    const [chart, setChart] = useState<IChartApi | null>(null);
    const chartContainerRef: MutableRefObject<any> = useRef<IDataRef>();
    const dataRef = useRef<IDataRef>({
        isActivated: false,
        sma9: null,
        sma21: null,
        sma200: null,
        ema12: null,
        ema26: null,
        ema200: null,
        candles: null,
        volume: null,
        fiboLines: [],
        resize$: new Subject(),
    });

    useEffect(() => {
        if (dataRef.current.isActivated || !chartContainerRef || !chartContainerRef.current) {
            return;
        }
        const width = chartContainerRef.current.clientWidth;
        const height = window.innerHeight - paddingHeight;

        const newChart = createChart(chartContainerRef.current, {
            layout: {
                background: { type: ColorType.Solid, color: backgroundColor },
                textColor,
            },
            timeScale: {
                visible: true,
                timeVisible: true,
                secondsVisible: false,
                rightOffset: 20,
            },
            width,
            height,
            autoSize: false,
        });
        dataRef.current.isActivated = true;
        setChart(newChart);
    }, [chartContainerRef, paddingHeight]);

    const handleResize = useCallback(() => {
        const width = chartContainerRef.current.clientWidth;
        const height = window.innerHeight - paddingHeight;
        dataRef.current.resize$.next({ width, height });
    }, [paddingHeight]);

    // resize
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        const sub = dataRef.current.resize$.pipe(debounceTime(200)).subscribe(({ width, height }) => {
            if (chart) {
                chart?.applyOptions({ width, height });
            }
        });

        return () => {
            sub?.unsubscribe();
            window.removeEventListener('resize', handleResize);
        };
    }, [chart, handleResize]);

    // Candles
    useEffect(() => {
        if (chart && data?.length > 0) {
            if (dataRef.current.candles) {
                chart.removeSeries(dataRef.current.candles);
            }
            // Chart data
            const candlestickSeries = chart.addCandlestickSeries({ lastValueVisible: false });
            candlestickSeries.setData(data);
            dataRef.current.candles = candlestickSeries;

            // Legend
            const getLastBar = (series: any) => {
                const lastIndex = series.dataByIndex(Infinity, -1);
                return series.dataByIndex(lastIndex);
            };

            const updateLegend = (param: any) => {
                const validCrosshairPoint = !(
                    param === undefined ||
                    param.time === undefined ||
                    param.point.x < 0 ||
                    param.point.y < 0
                );
                const bar = validCrosshairPoint
                    ? param.seriesData.get(candlestickSeries)
                    : getLastBar(candlestickSeries);
                if (bar) {
                    setSelectedCandle(bar);
                }
            };

            chart.subscribeCrosshairMove(updateLegend);

            const timeVisible = includes([TimeFrame.OneMinute, TimeFrame.OneHour, TimeFrame.FourHour], timeFrame);
            chart.applyOptions({ timeScale: { timeVisible: timeVisible } });

            updateLegend(undefined);
        }
    }, [data, chart, timeFrame]);

    // Fibo
    useEffect(() => {
        if (chart && data?.length > 0) {
            if (dataRef.current.fiboLines.length > 0) {
                dataRef.current.fiboLines.forEach((line) => {
                    dataRef.current.candles?.removePriceLine(line);
                });
                dataRef.current.fiboLines = [];
            }
            if (fibo) {
                // Price line
                const fiboLines = [
                    {
                        price:
                            fibo.trendDirection === TrendDirection.Up
                                ? parseFloat(fibo.point1.low as unknown as string)
                                : parseFloat(fibo.point1.high as unknown as string),
                        title: '0%',
                    },
                    {
                        price: fibo.fib236,
                        title: '23.6%',
                        color: '#F23645',
                    },
                    {
                        price: fibo.fib382,
                        title: '38.2%',
                        color: '#FF9800',
                    },
                    {
                        price: fibo.fib1000,
                        title: '100%',
                        color: '#787B86',
                    },
                    {
                        price: fibo.fib1618,
                        title: '161.8%',
                        color: '#2962FF',
                    },
                    {
                        price: fibo.fib2618,
                        title: '261.8%',
                        color: '#E91E63',
                    },
                    {
                        price: fibo.fib3618,
                        title: '361.8%',
                        color: '#66BB6A',
                    },
                    {
                        price: fibo.fib4236,
                        title: '423.6%',
                        color: '#089981',
                    },
                    {
                        price: fibo.fib4786,
                        title: '478.6%',
                        color: '#787B86',
                    },
                ];
                fiboLines.forEach((line) => {
                    const maxPriceLine = {
                        price: line.price,
                        color: line.color,
                        lineStyle: LineStyle.Solid,
                        axisLabelVisible: true,
                        title: line.title,
                    };
                    const lineModel = dataRef.current.candles?.createPriceLine(maxPriceLine);
                    lineModel && dataRef.current.fiboLines.push(lineModel);
                });
            }
        }
    }, [chart, data, fibo]);

    // Volume
    useEffect(() => {
        if (chart && data?.length > 0) {
            if (dataRef.current.volume) {
                chart.removeSeries(dataRef.current.volume);
                dataRef.current.volume = null;
            }
            if (showVolume) {
                const volumeSeries = chart.addHistogramSeries({
                    color: highColor,
                    priceFormat: {
                        type: 'volume',
                    },
                    priceScaleId: '',
                });
                dataRef.current.volume = volumeSeries;
                volumeSeries.priceScale().applyOptions({
                    scaleMargins: {
                        top: 0.7, // highest point of the series will be 70% away from the top
                        bottom: 0,
                    },
                });
                const volumeData = data.map(({ volume, close, time }, index) => {
                    if (!index) {
                        return { time, value: volume, color: highColor };
                    }
                    const lastCandle = data[index - 1];
                    const color = lastCandle.close > close ? lowColor : highColor;
                    return { time, value: volume, color };
                });
                volumeSeries.setData(volumeData);
            }
        }
    }, [data, chart, showVolume]);

    // EMA12
    useEffect(() => {
        if (chart) {
            if (dataRef.current.ema12) {
                chart.removeSeries(dataRef.current.ema12);
                dataRef.current.ema12 = null;
            }
            if (showEMA12 && data?.length > 0) {
                const lineSeriesEMA12 = chart.addLineSeries({
                    lineWidth: 2,
                    crosshairMarkerVisible: false,
                    lastValueVisible: false,
                    lineStyle: LineStyle.Solid,
                });
                dataRef.current.ema12 = lineSeriesEMA12;
                // (Price - EMA_previous) * K + EMA_previous
                let emaPrev = 1;
                const n = 12;
                const K = 2 / (n + 1);
                const lineDataEMA12 = data
                    .map((model) => {
                        const value = model.close * K + emaPrev * (1 - K);
                        emaPrev = value;
                        return { time: model.time, value };
                    })
                    .splice(12);
                lineSeriesEMA12.setData(lineDataEMA12);
            }
        }
    }, [chart, showEMA12, data]);

    // EMA26
    useEffect(() => {
        if (chart) {
            if (dataRef.current.ema26) {
                chart.removeSeries(dataRef.current.ema26);
                dataRef.current.ema26 = null;
            }
            if (showEMA26 && data?.length > 0) {
                const lineSeriesEMA26 = chart.addLineSeries({
                    lineWidth: 2,
                    crosshairMarkerVisible: false,
                    lastValueVisible: false,
                    lineStyle: LineStyle.Solid,
                    color: '#FF7F00',
                });
                dataRef.current.ema26 = lineSeriesEMA26;
                // (Price - EMA_previous) * K + EMA_previous
                let emaPrev = 1;
                const n = 26;
                const K = 2 / (n + 1);
                const lineDataEMA26 = data
                    .map((model) => {
                        const value = model.close * K + emaPrev * (1 - K);
                        emaPrev = value;
                        return { time: model.time, value };
                    })
                    .splice(26);
                lineSeriesEMA26.setData(lineDataEMA26);
            }
        }
    }, [chart, showEMA26, data]);

    // EMA200
    useEffect(() => {
        if (chart) {
            if (dataRef.current.ema200) {
                chart.removeSeries(dataRef.current.ema200);
                dataRef.current.ema200 = null;
            }
            if (showEMA200 && data?.length > 0) {
                const lineSeriesEMA200 = chart.addLineSeries({
                    lineWidth: 2,
                    crosshairMarkerVisible: false,
                    lastValueVisible: false,
                    lineStyle: LineStyle.Solid,
                    color: '#800000',
                });
                dataRef.current.ema200 = lineSeriesEMA200;
                // (Price - EMA_previous) * K + EMA_previous
                let emaPrev = 1;
                const n = 200;
                const K = 2 / (n + 1);
                const lineDataEMA200 = data
                    .map((model) => {
                        const value = model.close * K + emaPrev * (1 - K);
                        emaPrev = value;
                        return { time: model.time, value };
                    })
                    .splice(200);
                lineSeriesEMA200.setData(lineDataEMA200);
            }
        }
    }, [chart, showEMA200, data]);

    // SMA9
    useEffect(() => {
        if (chart) {
            if (dataRef.current.sma9) {
                chart.removeSeries(dataRef.current.sma9);
                dataRef.current.sma9 = null;
            }
            if (showSMA9 && data?.length > 0) {
                const lineSeriesSMA9 = chart.addLineSeries({
                    lineWidth: 2,
                    crosshairMarkerVisible: false,
                    lastValueVisible: false,
                    color: lineColor,
                });
                dataRef.current.sma9 = lineSeriesSMA9;
                let ma9Range: number[] = [];
                const lineDataSMA9 = data
                    .map((model) => {
                        if (ma9Range.length === 9) {
                            ma9Range = ma9Range.splice(1);
                        }
                        ma9Range.push(model.close);
                        const value = ma9Range.reduce((res, val) => res + val, 0) / 9;
                        return { time: model.time, value };
                    })
                    .splice(9);
                lineSeriesSMA9.setData(lineDataSMA9);
            }
        }
    }, [chart, showSMA9, data]);

    // SMA21
    useEffect(() => {
        if (chart) {
            if (dataRef.current.sma21) {
                chart.removeSeries(dataRef.current.sma21);
                dataRef.current.sma21 = null;
            }
            if (showSMA21 && data?.length > 0) {
                const lineSeriesSMA21 = chart.addLineSeries({
                    lineWidth: 2,
                    crosshairMarkerVisible: false,
                    lastValueVisible: false,
                    color: '#FF7F00',
                });
                dataRef.current.sma21 = lineSeriesSMA21;
                let ma21Range: number[] = [];
                const lineDataSMA9 = data
                    .map((model) => {
                        if (ma21Range.length === 21) {
                            ma21Range = ma21Range.splice(1);
                        }
                        ma21Range.push(model.close);
                        const value = ma21Range.reduce((res, val) => res + val, 0) / 21;
                        return { time: model.time, value };
                    })
                    .splice(21);
                lineSeriesSMA21.setData(lineDataSMA9);
            }
        }
    }, [chart, showSMA21, data]);

    // SMA200
    useEffect(() => {
        if (chart) {
            if (dataRef.current.sma200) {
                chart.removeSeries(dataRef.current.sma200);
                dataRef.current.sma200 = null;
            }
            if (showSMA200 && data?.length > 0) {
                const lineSeriesSMA200 = chart.addLineSeries({
                    lineWidth: 2,
                    crosshairMarkerVisible: false,
                    lastValueVisible: false,
                    color: '#800000',
                });
                dataRef.current.sma200 = lineSeriesSMA200;
                let ma200Range: number[] = [];
                const lineDataSMA200 = data
                    .map((model) => {
                        if (ma200Range.length === 200) {
                            ma200Range = ma200Range.splice(1);
                        }
                        ma200Range.push(model.close);
                        const value = ma200Range.reduce((res, val) => res + val, 0) / 200;
                        return { time: model.time, value };
                    })
                    .splice(200);
                lineSeriesSMA200.setData(lineDataSMA200);
            }
        }
    }, [chart, showSMA200, data]);

    // markers
    useEffect(() => {
        if (chart && data?.length > 0 && data[0].timeFrame === timeFrame) {
            const markers: SeriesMarker<Time>[] = [];
            if (fibo) {
                markers.push(
                    {
                        time: getTimeFormat(timeFrame, fibo.point1.time) as Time,
                        position: fibo.trendDirection === TrendDirection.Up ? 'belowBar' : 'aboveBar',
                        color: lineColor,
                        shape: fibo.trendDirection === TrendDirection.Up ? 'arrowUp' : 'arrowDown',
                        text: 'Point 1',
                    },
                    {
                        time: getTimeFormat(timeFrame, fibo.point2.time) as Time,
                        position: fibo.trendDirection === TrendDirection.Up ? 'aboveBar' : 'belowBar',
                        color: lineColor,
                        shape: fibo.trendDirection === TrendDirection.Up ? 'arrowDown' : 'arrowUp',
                        text: 'Point 2',
                    }
                );
            }

            if (showPattern || showPriceGap) {
                const trades = new Map<Date, ITrade>();
                let maxCandles = 365;
                const targetPercent = timeFrame === TimeFrame.OneHour ? -1 : -3;
                if (timeFrame === TimeFrame.OneDay) {
                    maxCandles = -365;
                } else if (timeFrame === TimeFrame.OneHour) {
                    maxCandles = -365 * 8 * 21;
                } else if (timeFrame === TimeFrame.OneMonth) {
                    maxCandles = -12;
                } else if (timeFrame === TimeFrame.FourHour) {
                    maxCandles = -365 * 2 * 21;
                }

                for (const [index, candle] of Object.entries(data).slice(maxCandles)) {
                    // patter v1
                    // if (false) {
                    //     const candleParts = getCandleParts(candle);
                    //
                    //     if (
                    //         candleParts.lowShadePercent > candleParts.bodyPercent &&
                    //         candleParts.highShadePercent < 10
                    //     ) {
                    //         const bodyRation = candleParts.lowShadePercent / candleParts.bodyPercent;
                    //         if (bodyRation > 2 && bodyRation < 3) {
                    //             markers.push({
                    //                 time: getTimeFormat(timeFrame, candle.time) as Time,
                    //                 position: 'aboveBar',
                    //                 color: '#0000FF',
                    //                 shape: 'arrowDown',
                    //                 text: 'Pattern',
                    //             });
                    //         }
                    //     }
                    // }
                    if (index === '0') {
                        continue;
                    }
                    if (showPriceGap) {
                        const prevCandle = data[parseInt(index) - 1];
                        const percent = getPercent(prevCandle.close, candle.open);
                        const positivePercent = percent < 0 ? percent * -1 : percent;

                        if (positivePercent > 2) {
                            markers.push({
                                time: candle.time as Time,
                                position: percent > 0 ? 'aboveBar' : 'belowBar',
                                color: percent > 0 ? highColor : lowColor,
                                shape: 'arrowDown',
                                text: `GAP ${percent.toFixed(2)}%`,
                            });
                        }
                    }

                    // big red candle
                    if (showPattern) {
                        const percent = getPercent(candle.open, candle.close);

                        trades.forEach((trade) => {
                            if (!trade.isExecuted && trade.target < candle.high) {
                                trade.isExecuted = true;
                                const diffDays = dayjs(candle.time).diff(dayjs(trade.time), 'day');
                                trade.executedRange = diffDays;
                                markers.push({
                                    time: candle.time as Time,
                                    position: 'aboveBar',
                                    color: highColor,
                                    shape: 'arrowDown',
                                    text: `Close ${diffDays}`,
                                });
                            }
                        });

                        if (percent < targetPercent) {
                            const candleParts = getCandleParts(candle);
                            trades.set(candle.time.toString(), {
                                buy: candle.close,
                                target: candle.close + candleParts.bodyValue * 0.4,
                                isExecuted: false,
                                time: candle.time as Date,
                            });
                            markers.push({
                                time: candle.time as Time,
                                position: percent > 0 ? 'aboveBar' : 'belowBar',
                                color: percent > 0 ? highColor : lowColor,
                                shape: 'arrowDown',
                                text: `${percent.toFixed(2)}%`,
                            });
                        }
                    }
                }

                if (showPattern) {
                    let result = 0;
                    let totalCount = trades.size;
                    let executedCount = 0;
                    const diffDays: number[] = [];
                    trades.forEach((trade) => {
                        if (trade.isExecuted) {
                            executedCount++;
                            result += getPercent(trade.buy, trade.target);
                            diffDays.push(trade.executedRange as number);
                        }
                    });
                    const sumDiff = diffDays.reduce((prev, current) => {
                        return prev + current;
                    }, 0);
                    const avgStr = (sumDiff / (diffDays.length || 1)).toFixed(2);
                    const msg = `Всего: ${totalCount} | Успешно: ${executedCount} | Ср. закрытие за: ${avgStr} | Итого: +${result.toFixed(2)}%`;
                    alert(msg);
                }
            }

            dataRef.current.candles?.setMarkers(sortBy(markers, 'time') as any);
        }
    }, [chart, showPattern, fibo, data, showPriceGap, timeFrame]);

    const candle = data.find((candle) => candle.time === selectedCandle?.time);

    const open = selectedCandle?.open || 0;
    const close = selectedCandle?.close || 0;
    const low = selectedCandle?.low || 0;
    const high = selectedCandle?.high || 0;
    const volume = candle ? getHumanReadableVolume(candle.volume) : 0;
    const candleChangeVal = (selectedCandle?.close || 0) - (selectedCandle?.open || 0);
    const candleChangePercent = (candleChangeVal * 100) / (selectedCandle?.open || 0);
    const symbol = open < close ? '+' : '';

    return (
        <div ref={chartContainerRef}>
            {showCurrentCandleDetails && (
                <div style={{ flex: '1 1 auto' }}>
                    <Typography
                        sx={{
                            fontSize: 14,
                            fontWeight: '400',
                            fontFamily: 'Trebuchet, sans-serif',
                            paddingLeft: '10px',
                        }}
                    >
                        Объем: {volume} | Мин.: {prettyPrintPrice(low)} | Макс.: {prettyPrintPrice(high)} | Откр.:{' '}
                        {prettyPrintPrice(open)} | Закр.: {prettyPrintPrice(close)} | Изменение: {symbol}
                        {candleChangePercent.toFixed(2)}% / {prettyPrintPrice(candleChangeVal)} ₽
                    </Typography>
                </div>
            )}
        </div>
    );
};
